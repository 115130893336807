<template>
  <v-main class="main">
    <loading-component class="loading" :propLoading="loading" />
    <header-component :propHeader="header" />
    <v-carousel hide-delimiter-background :height="carouselHeightPx" :cycle="false" :interval="hierarchy.getInterval()"
      :show-arrows="!loading" :hide-delimiters="loading" :touchless="loading" v-model="currentIndex" @change="change" :key="carouselKey">

      <v-carousel-item v-for="(sensor, i) in sensorList" :key="i">

        <div class="carousel-content">

          <v-container>

            <sensor-sub-header-component  :propImage="subTitle.class"
              :propSubtitle="subTitle.caption + 'モニタリング中'" 
              :propDataType="dataType" 
              :propRotation="rotation"/>

            <sensor-component :propUser="user" :propSummary="summary" :propArea="propArea" :propGetSummary="getSummary"
              :propSensor="sensor" :propSensorNumber="sensor.sensorNumber" :propSource="propSource"
              :propDate="searchDatePrimary" :propPeriod="searchPeriod" :propDateSecondary="searchDateSecondary"
              :propSecondaryFlag="searchSecondaryFlag" :propGetSummarySensor="getSummarySensor"
              :propSearchSummarySensor="searchSummarySensor" :propLoading="loading" :propIndex="i"
              :propCurrentIndex="currentIndex" :propRotation="rotation" :propHeader="header"
              :propDataType="dataType" @setDataType="setDataType"
              @stopProcessing="stopProcessing" />
          </v-container>
        </div>
      </v-carousel-item>
    </v-carousel>
    <fab-component :propUser="user" :propArea="propArea" :propFabPrimary="fab" :propFabRotation="fabRotation"
      :propGetLoading="getLoading" />
  </v-main>
</template>

<script>
import mixinApp from "../store/mixin.app";
import mixinSensor from "../store/mixin.sensor";
import mixinDetail from "../store/mixin.detail";
import LoadingComponent from "../components/LoadingComponent";
import HeaderComponent from "../components/HeaderComponent";
import SensorSubHeaderComponent from "../components/SensorSubHeaderComponent";
import FabComponent from "../components/FabComponent";
import SensorComponent from "../components/SensorComponent";
import store from "../store";
import models from "../store/models";
import constant from "../store/constant";

const vm = {
  mixins: [mixinApp, mixinSensor, mixinDetail],
  components: {
    LoadingComponent,
    HeaderComponent,
    SensorSubHeaderComponent,
    FabComponent,
    SensorComponent,
  },
  props: {
    propSensorNumber: {
      type: String,
      default: null
    },
    propHierarchyCode: {
      type: Object,
      default: null
    },
    propHierarchy: {
      type: Object,
      default: null
    },
    propSearchDate: {
      type: Object,
      default: null
    },
    propSearchPeriodValue: {
      type: Object,
      default: null
    },
    propRotationSelectNo:{
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      processing: false,
      currentIndex: 0,
      sensorDict: {},
      doneSearchSensor: false,
      carouselKey: false,
      dataType: "co2c",
    };
  },
  computed: {
    loading() {
      return (
        //!(this.user && this.summary && this.doneSearchSensor) ||
        this.processing
      );
    },
    header() {
      //Memo:マージ後要見直し
      // return models.createHeader(
      //   this.hierarchy.hierarchyName,
      //   "icon-sensor",
      //   this.getUserTimestamp
      // );
      return models.createHeader(
        this.hierarchy.hierarchyName,
        "icon-loca",
        this.getUserTimestamp
      );
    },
    fab() {
      //Memo:マージ後要見直し
      // return models.createFab("mdi-arrow-left", () => {
      //   this.transitionSource(this.user, this.summary, this.propArea);
      // });
      //戻るボタン
      var icon = "";
      if (this.getSource() == "floor") {
        icon = "icon_btn_floormap_3x";
      } else if (this.getSource() == "ranking") {
        icon = "icon-fixed";
      }
      return models.createFab(icon, () => {
        this.transitionSource(this.user, this.summary, this.propArea, this.rotation.no);
      });
    },
    sensorList() {
      const sensorList = this.summaryFloor
        ? this.summaryFloor.getDisplaySensorList(this.user)
        : [];
      return sensorList;
    },
    currentSensor() {
      return this.sensorList[this.currentIndex];
    },


    /** センサー検索条件:検索日Primary (UnixTime) */
    searchDatePrimary: {
      get: () => {
        return store.getters[constant.storeSummaryGettersSearchDatePrimary()];
      },
      set: (value) => {
        return store.dispatch(constant.storeSummarySetSearchDatePrimary(), value);
      },
    },
    /** センサー検索条件:検索日Secondary (UnixTime) */
    searchDateSecondary: {
      get: () => {
        return store.getters[constant.storeSummaryGettersSearchDateSecondary()];
      },
      set: (value) => {
        return store.dispatch(constant.storeSummarySetSearchDateSecondary(), value);
      },
    },
    /** センサー検索条件:検索日付間隔 */
    searchPeriod: {
      get: () => {
        return store.getters[constant.storeSummaryGettersSearchPeriod()];
      },
      set: (value) => {
        return store.dispatch(constant.storeSummarySetSearchPeriod(), value);
      },
    },
    /** センサー検索条件:Secondary検索の有効フラグ */
    searchSecondaryFlag: {
      get: () => {
        return store.getters[constant.storeSummaryGettersSearchSecondaryFlag()];
      },
      set: (value) => {
        return store.dispatch(constant.storeSummarySetSearchSecondaryFlag(), value);
      },
    },
    subTitle() {
      return this.getParamType(this.selectNo).mode;
    },
    rotation() {
      const ret = {};
      ret.func = this.rotationStatus;
      ret.no = this.selectNo;
      return ret;
    },
    fabRotation() {
      return models.createFab("icon-rotation", this.fabRotationStatus);
    },
  },
  methods: {
    stopProcessing() {
      this.processing = false;
    },
    setDataType(type) {
      this.dataType = type;
      //一旦無効化 ボタンとRotationの相互間がうまくいかず。
      // // Rotationのセット
      // const selectNo = 
      //   type == "temp" ? "1" : //温度
      //   type == "humi" ? "2" : //湿度
      //   "0"; //CO2
      // this.changeStatus(selectNo);
    },
    setDataTypeBySelectNo(selectNo) {
      const dataType = 
        selectNo == "1" ? "temp" :
        selectNo == "2" ? "humi" :
        "co2c";

      this.dataType = dataType;
    },
    equalsPrimaryConditions(sensor) {
      const condition = sensor.primary.condition;
      const searchDate = this.searchDatePrimary;
      const searchPeriod = this.searchPeriod.value;
      return (
        searchDate == condition.searchDate &&
        searchPeriod === condition.searchPeriod
      );
    },
    equalsSecondaryConditions(sensor) {
      const condition = sensor.secondary.condition;
      const searchDate = this.searchDateSecondary;
      const searchPeriod = this.searchPeriod.value;
      return (
        condition != null &&
        searchDate == condition.searchDate &&
        searchPeriod === condition.searchPeriod
      );
    },
    change() {
      if (!this.sensorList.length) {
        return;
      }
      // currentIndex:遷移後のセンサー

      const currentSummarySensor = this.getSummarySensor(this.currentSensor);

      //過去の検索結果なし or 過去の検索条件と現在の過去の検索条件で差異がある場合
      if (
        currentSummarySensor.isEmpty() ||
        !this.equalsPrimaryConditions(currentSummarySensor)
      ) {
        this.searchSummarySensor(
          this.searchDatePrimary,
          this.searchPeriod.value,
          constant.partitionPrimary()
        );
      }

      //Secondary非表示の場合、データクリア
      if (!this.searchSecondaryFlag) {
        currentSummarySensor.clearSecondary();
      }
      //過去の検索結果なし or 過去の検索条件と現在の過去の検索条件で差異がある場合
      else if (
        currentSummarySensor.isEmptySecondary() ||
        !this.equalsSecondaryConditions(currentSummarySensor)
      ) {
        this.searchSummarySensor(
          this.searchDateSecondary,
          this.searchPeriod.value,
          constant.partitionSecondary()
        );
      }
    },
    getSummarySensor(sensor) {
      const sensorNumber = sensor == null ? null : sensor.sensorNumber;
      return this.getSensorData(sensorNumber);
    },
    createSearchCondition() {
      return this.summaryFloor
        ? this.summaryFloor.condition
        : this.user.createSearchCondition();
    },
    createSensorSearchCondition(
      searchDate,
      searchPeriodValue,
      partition,
      sensorNumber = null,
      hierarchyCode = null
    ) {
      const ret = models.createSearchCondition();

      ret.userKind = this.user.userKind;
      ret.searchUnit = constant.searchUnitSensor();
      ret.hierarchyCode = hierarchyCode
        ? hierarchyCode
        : this.hierarchy.hierarchyCode;
      ret.sensorNumber = sensorNumber
        ? sensorNumber
        : this.currentSensor
          ? this.currentSensor.sensorNumber
          : null;
      ret.searchDate = searchDate;
      ret.searchPeriod = searchPeriodValue || this.getSearchPeriodValue();
      ret.partition = partition || constant.partitionPrimary();
      console.log("createSensorSearchCondition", ret, this.user, this.currentSensor, this.sensorList, this.currentIndex);
      return ret;
    },
    searchSummarySensor(
      searchDate,
      searchPeriod,
      partition,
      sensorNumber,
      hierarchyCode
    ) {
      this.processing = true;
      store.dispatch(
        constant.storeSummarySearch(),
        this.createSensorSearchCondition(
          searchDate,
          searchPeriod,
          partition,
          sensorNumber,
          hierarchyCode
        )
      );
    },
    setSummarySensor(summary) {
      this.sensorDict[this.currentSensor.sensorNumber].setSummary(summary);
      this.sensorDict = Object.assign({}, this.sensorDict);
      this.doneSearchSensor = true;
    },
    getsearchDatePrimaryDefault() {
      return this.propArea
        ? this.propArea.condition.searchDateEnd
        : constant.toUnixTime(constant.today());
    },
    getSearchPeriodValue() {
      return this.propArea
        ? this.propArea.condition.getSearchPeriodValue()
        : constant.searchPeriodDefault();
    },
    getSearchPeriod() {
      return this.propArea
        ? this.propArea.condition.getSearchPeriod()
        : constant.createSearchPeriodDefault();
    },
    getSearchDateSecondaryDefault() {
      const ret = constant.today();

      ret.setDate(ret.getDate() - 7);
      return constant.toUnixTime(ret);
    },
    getUserTimestamp() {
      if (this.user?.isManager()) {
        return 0;
      }
      return this.summary?.getLatestTimestamp();
    },
    checkAuthorized() {
      if (!this.user || this.user.unauthorized()) {
        console.log("autoUpdating transitionRoot");
        this.transitionRoot();
      }
    },
    autoUpdating() {
      this.startInterval(this.createSearchCondition);
    },
    initializeSubscribe() {
      this.unsubscribe = store
        .subscribe((mutation, state) => {
          console.log("SensorUnit View mutation state", mutation.type, mutation, state);
          if (mutation.type === constant.storeSummarySearch()) {
            this.processing = false

            const summary = state.summary.summary;
            //processingの中止処理はChartの描画後に設定。
            //this.processing = false;
            //グレーの場合はprocessingを中止
            this.processing = false

            if (!summary.detailList.length) {
              this.processing = false;
            }

            if (summary.condition.isSearchUnitSensor()) {
              this.setSummarySensor(summary);
            } else {
              this.setSummary(summary);
              this.searchSummary();
            }
          }
        })
        .bind(this);
    },
    rotationStatus(no) {
      this.changeStatus(no);
      // DataType
      const type = 
        no == "1" ? "temp" : //温度
        no == "2" ? "humi" : //湿度
        "co2c"; //CO2      
      this.dataType = type;

    },
    fabRotationStatus() {
      this.changeStatus("");
      const no = this.selectNo
      // DataType
      const type = 
        no == "1" ? "temp" : //温度
        no == "2" ? "humi" : //湿度
        "co2c"; //CO2      
      this.dataType = type;
    },
    /** カルーセルの再描画、これを実行すると、グラフのサイズが調整される */
    reRenderCarousel() {
        this.carouselKey = !this.carouselKey;
    },

  },
  created() {
    console.log("created");
  },
  mounted() {
      this.$nextTick(function () {
          // ビュー全体がレンダリングされた後にのみ実行
          // カルーセルの再描画を少し待機後実行することで、正しく画面表示
          setTimeout(this.reRenderCarousel, 1000);
      });
  },
  watch: {
    processing() {
      console.log("change processing", this.processing);
    },
  },
  /** 画面遷移後の処理 */
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.checkAuthorized();

      console.log("beforeRouteEnter", from, to, vm, vm.propHierarchy, vm.propSensorNumber, vm.summaryFloor, vm.user, vm.propSearchDate);
      const searchDate = vm.propSearchDate ? vm.propSearchDate : constant.toUnixTime(constant.today());
      const searchPeriodValue = vm.propSearchPeriodValue ? vm.propSearchPeriodValue : constant.searchPeriodDefault();
      //ToDo: 左側は切替完了。右側のグラフが切り替えられるように対応必要
      vm.selectNo = vm.propRotationSelectNo;
      vm.setDataTypeBySelectNo(vm.propRotationSelectNo);

      if (!vm || !vm.user || !vm.user.hierarchy || !vm.user.hierarchy.hierarchyCode) {
        console.log("beforeRouteEnter no user", from, to, vm)
        return;
      }
      const hierarchyCode = vm.propHierarchy && vm.propHierarchy.hierarchyCode ? vm.propHierarchy.hierarchyCode : vm.user.hierarchy.hierarchyCode;
      const userKind = vm.propArea && vm.propArea.condition && vm.propArea.condition.userKind ? vm.propArea.condition.userKind : constant.userKindUser();
      const partition = constant.partitionPrimary();

      if (vm.propArea && vm.propHierarchy) {
        //エリア画面から来た場合は、propAreaとpropHierarchyを保持
        //hierarchyをpropHierarchyの内容に切り替え
        vm.user.setCurrentHierarchy(vm.propHierarchy);

        //Secondaryを無効に
        vm.searchSecondaryFlag = false;
      }
      if (!vm.sensorList || vm.sensorList.length == 0 || vm.propArea && vm.propHierarchy) {
        //フロアの検索結果がない場合orエリア画面から来た場合、検索
        const condition = models.createSearchCondition();
        condition.userKind = userKind;
        condition.searchUnit = constant.searchUnitFloor();
        condition.hierarchyCode = hierarchyCode;
        vm.processing = true;
        console.log("beforeRouteEnter search Floor", condition);
        await store.dispatch(constant.storeSummarySearch(), condition);
        vm.processing = false;
      }

      const selectedIndex = vm.sensorList.findIndex(
        (val) => val.sensorNumber === vm.propSensorNumber
      );
      vm.currentIndex = selectedIndex;

      const sensorNumber = vm.propSensorNumber
        ? vm.propSensorNumber
        : vm.sensorList[vm.currentIndex]
          ? vm.sensorList[vm.currentIndex].sensorNumber
          : null;

      console.log("beforeRouteEnter searchSummarySensor", vm.currentIndex, vm.currentSensor, vm.sensorList, vm.propHierarchyCode, vm.propSensorNumber, sensorNumber, searchDate, searchPeriodValue, hierarchyCode);

      //引数を検索条件にセット
      vm.searchDatePrimary = searchDate;
      vm.searchPeriod = searchPeriodValue;

      // 初期化処理

      await vm.searchSummarySensor(
        searchDate,
        searchPeriodValue,
        partition,
        sensorNumber,
        hierarchyCode
      );

      //Secondaryを無効に
      const currentSummarySensor = vm.getSummarySensor(vm.currentSensor);
      vm.searchSecondaryFlag = false;
      currentSummarySensor.clearSecondary();

      vm.autoUpdating();
      vm.initializeSubscribe();

      next();
    });
  },
};

export default vm;
</script>

<style scoped>
@import '../assets/css/sensor.min.css';

.loading {
  z-index: 1;
}

.carousel-content {
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
