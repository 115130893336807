<template>
  <v-container class="container sensor-container">
    <v-row class="sensor-row" align="center" justify="center">
      <v-col cols="3" v-if="isShowRankingDetail">
        <ranking-detail-component :propUser="user" :propSummary="propGetSummary()" :propDetail="detail"
          :propClickable="false" :propIsSensor="true" :propRotation="rotation" />
      </v-col>
      <v-col >
        <div class="chart-area">
          <div class="chart-sub-header">
            <div class="search-condition">

              <date-picker-component class="search-condition-item date-component" :propUser="user"
                :propDate="searchDatePrimary" :propLabel="searchDateLabel" :propDisabled="disabledDateSearch"
                @change="searchSummarySensorPrimary" />
              <search-period-component class="search-condition-item" :propSearchPeriod="searchPeriod"
                :propDisabled="disabledPeriodSearch" @change="changeSearchPeriod" />

              <div v-if="!isIphone">
                <div v-if="strategy.enabled()" class="compare">
                  <date-picker-component class="compare-item date-component" :propUser="user"
                    :propDate="searchDateSecondary" :propLabel="compareDateLabel" :propDisabled="disabledDateSearch"
                    @change="searchSummarySensorSecondary" />
                  <!-- <v-btn
                                      fab
                                      x-small
                                      class="compare-item"
                                      :disabled="disabledDateSearch"
                                      @click="compareSecondary"
                                  >
                                      <v-icon>{{ compareSecondaryIcon }}</v-icon>
                                  </v-btn> -->

                  <div class="compare-checkbox">
                    <v-switch
                      fab
                      small
                      flat
                      color="primary lighten-2"
                      class="compare-item"
                      :disabled="disabledDateSearch"
                      v-model="searchSecondaryFlag"
                      @click="compareSecondary"
                    ></v-switch>
                  </div>



                </div>

              </div>


            </div>
            <div class="chart_icon_button">
              <span v-if="strategy.enabled()">
              

                <v-btn color="transparent" fab :small="isIphone" @click="zoomIn">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        v-bind="attrs"
                        v-on="on"
                        color="white"
                        id="zoomIn-icon"
                        >mdi-magnify-plus-outline</v-icon>     

                    </template>
                    <span>グラフはマウスホイールでも拡大縮小できます</span>
                  </v-tooltip>
                </v-btn>
                <v-btn color="transparent" fab :small="isIphone" @click="zoomOut">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        v-bind="attrs"
                        v-on="on"
                        color="white"
                        id="zoomOut-icon"
                        >mdi-magnify-minus-outline</v-icon>     

                    </template>
                    <span>グラフはマウスホイールでも拡大縮小できます</span>
                  </v-tooltip>
                </v-btn>
              </span>
<!--               
                <v-btn color="transparent" fab :small="isIphone" @click = "setTypeCo2c">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }"> 
                      <img
                        v-bind="attrs"
                        v-on="on"
                        v-if="isCo2Data()"
                        class="icon_co2c"
                        src="../assets/icon_co2c.png"
                        :style="bindStyle"
                        />
                      <img
                        v-bind="attrs"
                        v-on="on"                  
                        v-else
                        class="icon_co2c"
                        src="../assets/icon_co2c_gray.png"
                        :style="bindStyle"
                        />  
                    </template>
                    <span>CO2のグラフを表示</span>
                  </v-tooltip>
                </v-btn>
                <v-btn color="transparent" fab :small="isIphone" @click = "setTypeTemp">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }"> 
                      <img
                        v-bind="attrs"
                        v-on="on"
                        v-if="isTempData()"
                        class="icon_thermometer"
                        src="../assets/icon_thermometer.png"
                        :style="bindStyle"
                      />
                      <img
                        v-bind="attrs"
                        v-on="on"
                        v-else
                        class="icon_thermometer"
                        src="../assets/icon_thermometer_gray.png"
                        :style="bindStyle"
                      />
                    </template>
                    <span>温度のグラフを表示</span>
                  </v-tooltip>
                </v-btn>

                <v-btn color="transparent" fab :small="isIphone" @click = "setTypeHumi">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }"> 
                      <img
                        v-bind="attrs"
                        v-on="on"
                        v-if="isHumiData()"
                        class="icon_humidity"
                        src="../assets/icon_humidity.png"
                        :style="bindStyle"
                        active="true"
                        />
                      <img
                        v-bind="attrs"
                        v-on="on"
                        v-else
                        class="icon_humidity"
                        src="../assets/icon_humidity_gray.png"
                        :style="bindStyle"
                        />
                    </template>
                    <span>湿度のグラフを表示</span>
                  </v-tooltip>
                </v-btn> -->

              </div>
          </div>
          <sensor-chart-component
            :propSensorNumber="propSensorNumber"
            :propRotation="rotation"
            :propSensorChartWidth="sensorChartWidth"
            :propDataType="propDataType"
            @stopProcessing="stopProcessing"
          />
        </div>
      </v-col>
    </v-row>
    <div class="footer" v-if="user.isUser()">
      <div>{{ header.dispTimestamp() }} 更新</div>
    </div>
  </v-container>
</template>

<script>
import mixinApp from "../store/mixin.app";
import mixinSensor from "../store/mixin.sensor";
import mixinDetail from "../store/mixin.detail";
import RankingDetailComponent from "../components/RankingDetailComponent";
import SensorChartComponent from "../components/SensorChartComponent";
import DatePickerComponent from "../components/DatePickerComponent";
import SearchPeriodComponent from "../components/SearchPeriodComponent";
import constant from "../store/constant";
import store from "../store";
import models from "../store/models";

const vm = {
  mixins: [mixinApp, mixinSensor, mixinDetail],
  props: {
    propGetSummary: {
      type: Function,
      default: null
    },
    propGetSummarySensor: {
      type: Function,
      default: null
    },
    propSearchSummarySensor: {
      type: Function,
      default: null
    },
    propLoading: {
      type: Boolean,
      default: false
    },
    propIndex: {
      type: Number,
      default: 0
    },
    propCurrentIndex: {
      type: Number,
      default: 0
    },
    propSensorNumber: {
      type: String,
      default: null
    },
    propRotation: {
      type: Object,
      default: null
    },
    propHeader: {
      type: Object,
      default: null
    },
    propDataType: {
      type: String,
      default: "co2c"
    }
  },
  components: {
    RankingDetailComponent,
    SensorChartComponent,
    DatePickerComponent,
    SearchPeriodComponent,
  },
  data() {
    return {
      

    };
  },
  computed: {
    detail() {
      return this.getDetail(this.propSensorNumber);
    },
    header() {
      return models.createHeader(
        "footer",
        "footer",
        this.summarySensor.primary.getLatestTimestamp
      );
    },
    summarySensor() {
      return this.getSensorData(this.propSensorNumber);
    },
    searchDateLabel() {
      return "検索日付";
    },
    compareDateLabel() {
      return "比較日付";
    },
    compareSecondaryIcon() {
      // return this.compareSecondaryFlag
      return this.searchSecondaryFlag
        ? "mdi-eye-outline"
        : "mdi-eye-off-outline";
    },
    disabledPeriodSearch() {
      return this.strategy.disabled() || this.propLoading;
    },
    disabledDateSearch() {
      return this.propLoading;
    },
    /** センサー検索条件:検索日Primary (UnixTime) */
    searchDatePrimary: {
      get: () => {
        return store.getters[constant.storeSummaryGettersSearchDatePrimary()];
      },
      set: (value) => {
        return store.dispatch(
          constant.storeSummarySetSearchDatePrimary(),
          value
        );
      },
    },
    /** センサー検索条件:検索日Secondary (UnixTime) */
    searchDateSecondary: {
      get: () => {
        return store.getters[constant.storeSummaryGettersSearchDateSecondary()];
      },
      set: (value) => {
        return store.dispatch(
          constant.storeSummarySetSearchDateSecondary(),
          value
        );
      },
    },
    /** センサー検索条件:検索日Secondary (UnixTime) */
    searchPeriod: {
      get: () => {
        return store.getters[constant.storeSummaryGettersSearchPeriod()];
      },
      set: (value) => {
        return store.dispatch(constant.storeSummarySetSearchPeriod(), value);
      },
    },
    /** センサー検索条件:検索日Secondary (UnixTime) */
    searchSecondaryFlag: {
      get: () => {
        return store.getters[constant.storeSummaryGettersSearchSecondaryFlag()];
      },
      set: (value) => {
        return store.dispatch(
          constant.storeSummarySetSearchSecondaryFlag(),
          value
        );
      },
    },
    rotation() {
      return this.propRotation;
    },
    isShowRankingDetail() {
      return this.innerWidth > 820;
    },
    sensorChartWidth() {
      if(this.innerWidth > 820) {
        return this.innerWidth * 0.65
      }
      return this.innerWidth * 0.8
    }
  },
  methods: {

    // isuser(){
    //   return this.user.isUser();
    // },
    /**
     * 0 > co2c
     * 1 > temp
     * 2 > humi
     */
    setSubs(no, key) {
        var priority = this.getParamType(no).priority;
        var arr = this.getParamType(priority[key-1]);
        return arr;
    },
    setTypeCo2c(){
      // this.propDataType = "co2c";
      this.$emit("setDataType","co2c");
    },
    setTypeTemp(){
      // this.propDataType = "temp";
      this.$emit("setDataType","temp");
    },
    setTypeHumi(){
      // this.propDataType = "humi";
      this.$emit("setDataType","humi");
    },
    /** グラフのズームイン */
    zoomIn(){
      //ズームが効果ある領域
      const rect = document.querySelector(".sensor-chart svg > g > rect")
      // 画面の左端から、要素の左端/上端までの距離 + 10px(ちょっと内側)
      const clientRect = rect.getBoundingClientRect()
      const x = clientRect.left + 10;
      const y = clientRect.top + 10;
      // ホイールの動きをエミュレート / APIの直接呼出しは、複雑すぎたため挫折
      const evt = new WheelEvent( "mousewheel", {
        clientX : x,
        clientY : y,
        wheelDelta: -120, // - にすると拡大/+にすると縮小
        wheelDeltaY: -120, // - にすると拡大/+にすると縮小
      }) ;
      evt.initEvent('mousewheel', true, true); 
      rect.dispatchEvent(evt);
    },
    /** グラフのズームアウト */
    zoomOut(){
      //ズームが効果ある領域
      const rect = document.querySelector(".sensor-chart svg > g > rect")
      // 画面の左端から、要素の左端/上端までの距離 + 10px(ちょっと内側)
      const clientRect = rect.getBoundingClientRect()
      const x = clientRect.left + 10;
      const y = clientRect.top + 10;
      // ホイールの動きをエミュレート / APIの直接呼出しは、複雑すぎたため挫折
      const evt = new WheelEvent( "mousewheel", {
        clientX : x,
        clientY : y,
        wheelDelta: +120, // - にすると拡大/+にすると縮小
        wheelDeltaY: +120, // - にすると拡大/+にすると縮小
      }) ;
      evt.initEvent('mousewheel', true, true); 
      rect.dispatchEvent(evt);
    },

    /** グラフ値 */
    isCo2Data(){
      return this.propDataType == "co2c";
    },
    isTempData(){
      return this.propDataType == "temp";
    },
    isHumiData(){
      return this.propDataType == "humi";
    },
    stopProcessing() {
      this.$emit("stopProcessing");
    },
    searchPeriodFormat() {
      this.searchPeriod = Object.assign({}, this.propPeriod);
      return constant.createSearchPeriod(this.searchPeriod.value);
    },
    searchSummarySensor(date, partition) {
      this.propSearchSummarySensor(date, this.searchPeriod.value, partition);
    },
    searchSummarySensorPrimary(date) {
      this.summarySensor.clearPrimary();
      this.searchDatePrimary = date;
      this.searchSummarySensor(date, constant.partitionPrimary());
    },
    searchSummarySensorSecondary(date) {
      this.searchDateSecondary = date;

      if (!this.searchSecondaryFlag) {
        this.summarySensor.clearSecondary();
        return;
      }

      this.searchSummarySensor(date, constant.partitionSecondary());
    },
    changeSearchPeriod(searchPeriod) {
      this.searchPeriod = searchPeriod;
      this.searchSummarySensorPrimary(this.searchDatePrimary);
      this.searchSummarySensorSecondary(this.searchDateSecondary);
    },
    compareSecondary() {
      this.searchSummarySensorSecondary(this.searchDateSecondary);
    },
    isCurrentIndex() {
      return this.propIndex == this.propCurrentIndex;
    },
    startGraphInterval() {
      if (this.interval) {
        return;
      }
      if (this.user.isManager()) {
        return;
      }
      this.interval = setInterval(
        (() => {
          //バックグラウンド処理も全て同じ検索条件で検索するため、現在のセンサのみデータ更新
          if (this.isCurrentIndex()) {
            this.searchSummarySensorPrimary(
              constant.toUnixTime(constant.today())
            );
          }
        }).bind(this),
        constant.intervalMs()
      );
    },
  },
  created() {
    this.startGraphInterval();
  },
  watch: {},
};

export default vm;
</script>

<style scoped>
.chart-area {
  height: var(--chart-area-card-height);
  border-style: var(--card-border-style);
  border-color: var(--grid-background-color);
}

.search-condition {
  display: flex;
  float: left;
}

.date-component {
  margin-left: 2vw;
}

.compare {
  display: flex;
  align-items: center;
}

.compare-item {
  margin-right: 16px;
}

.search-condition-item .compare-item {
  display: inline-block;
}

.line {
  width: 128px;
  border: 1.5px dashed white;
}

.chart_icon_button {
  /* width: 2.8vw !important; */
  margin-left: auto;
  margin-right: 2vw;
  margin-top: 2px;
  /* min-width: 180px; */

  float: right;
}
.chart_icon_button img{
  max-width: 48px !important;
  max-height: 40px !important;
  height: 36px !important;
  margin-left: 0vw;
}
.chart_icon_button .selected{
  opacity: 0.08;
}

#zoomIn-icon,#zoomOut-icon{
  font-size: 32px;
}


</style>
