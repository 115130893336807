<template>
  <!-- <div class="sensor-chart" :style="bindStyle" v-if="isCo2Data">
    <GChart v-if="hasChart" type="LineChart" :data="chartData_co2c" :options="chartOption_co2c" @ready="onChartReady"/>            
  </div>
  <div class="sensor-chart" :style="bindStyle" v-else-if="isTempData">
    <GChart v-if="hasChart" type="LineChart" :data="chartData_temp" :options="chartOption_temp" @ready="onChartReady"/>
  </div>  
  <div class="sensor-chart" :style="bindStyle" v-else-if="isHumiData">
    <GChart v-if="hasChart" type="LineChart" :data="chartData_humi" :options="chartOption_humi" @ready="onChartReady"/>
  </div> -->
  <div class="sensor-chart" :style="bindStyle">
    <GChart v-if="hasChart" type="LineChart" :data="chartData_visible()" :options="chartOption_visible()" @ready="onChartReady"/>
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";
import mixinApp from "../store/mixin.app";
import mixinDetail from "../store/mixin.detail";

const vm = {
  mixins: [mixinApp, mixinDetail],
  props: {
    propSensorNumber: {
      type: String,
      default: null
    },
    propRotation: {
      type: Object,
      default: null
    },
    propSensorChartWidth: {
      type: Number,
      default: 0
    },
    propDataType: {
      type: String,
      default: "co2c"
    },    
  },
  components: {
    GChart,
  },
  data() {
    return {
      /** 退避用グラフ値 */
      chartValue: null,
      chartCo2cValue: null,
      chartTempValue: null,
      chartHumiValue: null,
      /** 退避用Primary検索条件 */
      currentPrimaryCondition: this.getSensorData(this.propSensorNumber).primary
        .condition,
      /** 退避用Secondary検索条件 */
      currentSecondaryCondition: this.getSensorData(this.propSensorNumber)
        .secondary.condition,

      co2cChart: false,
      tempChart: false,
      humiChart: false,
    };
  },
  computed: {
    bindStyle() {
      return {
        "--chart-height": this.chartHeight + "px",
        "--chart-width": this.chartWidth + "px",
      };
    },
    sensorData() {
      return this.getSensorData(this.propSensorNumber);
    },
    sensorDataPrimary() {
      return this.sensorData.primary;
    },
    sensorDataSecondary() {
      return this.sensorData.secondary;
    },
    hasChart() {
      return this.hierarchy && this.sensorData.isNotEmpty();
    },
    chartHeight() {
      return this.innerHeight * 0.65;
    },
    chartWidth() {
          return this.propSensorChartWidth;
    },
    /** グラフ値 */
    isCo2Data(){
      return this.propDataType == "co2c";
    },
    isTempData(){
      return this.propDataType == "temp";
    },
    isHumiData(){
      return this.propDataType == "humi";
    },

    chartData_co2c() {
      return this.chartData("co2c");
    },
    chartData_temp() {
      return this.chartData("temp");
    },
    chartData_humi() {
      return this.chartData("humi");
    },
    /** グラフ設定 */

    chartOption_co2c() {
      return this.chartOption("co2c");  
    },
    chartOption_temp() {
      return this.chartOption("temp");  
    },
    chartOption_humi() {
      return this.chartOption("humi");  
    },

  },
  methods: {
    /** グラフ値 */
    chartData_visible(){
      if (this.propDataType == "temp"){
        return this.chartData("temp");
      } else if (this.propDataType == "humi"){
        return this.chartData("humi");
      } else {
        return this.chartData("co2c");
      }
    },

    getChartValue(type){
      if(type=="temp"){
        return this.chartTempValue;
      } else if(type=="humi"){
        return this.chartHumiValue;
      } else {
        return this.chartCo2cValue;
      }
    },
    setChartValue(type,chartValue){
      if(type=="temp"){
        this.chartTempValue = chartValue;
      } else if(type=="humi"){
        this.chartHumiValue = chartValue;
      } else {
        this.chartCo2cValue = chartValue;
      }

    },
    chartData(dataType) {
      //センサーデータの画面再描画を、値が変更した場合のみ実施する
      //マップ画面の検索時も結果に変更があるとし、全てのグラフ画面に画面描画が発生するため、データが変更したときのみ画面再描画する。
      const beforePrimaryCondition = this.currentPrimaryCondition;
      const beforeSecondaryCondition = this.currentSecondaryCondition;
      const afterPrimaryCondition = this.sensorDataPrimary.condition;
      const afterSecondaryCondition = this.sensorDataSecondary.condition;
      if (
        // 前回実行分がなければ画面再描画
        this.chartValue == null || //何度か処理すると、PrimaryとSecondaryのsensorNumberが一致しないケースがあったため
        ((afterPrimaryCondition.sensorNumber ==
          afterSecondaryCondition.sensorNumber ||
          afterSecondaryCondition.sensorNumber == null) &&
          //検索条件が不一致なら画面再描画
          (beforePrimaryCondition.searchDate !=
            afterPrimaryCondition.searchDate ||
            beforePrimaryCondition.searchPeriod !=
            afterPrimaryCondition.searchPeriod ||
            beforeSecondaryCondition.searchDate !=
            afterSecondaryCondition.searchDate ||
            beforeSecondaryCondition.searchPeriod !=
            afterSecondaryCondition.searchPeriod ||
            //検索条件が一致でも実行時間executedDatetimeが異なるなら画面再描画
            (beforePrimaryCondition.searchDate ==
              afterPrimaryCondition.searchDate &&
              beforePrimaryCondition.searchPeriod ==
              afterPrimaryCondition.searchPeriod &&
              beforeSecondaryCondition.searchDate ==
              afterSecondaryCondition.searchDate &&
              beforeSecondaryCondition.searchPeriod ==
              afterSecondaryCondition.searchPeriod &&
              (beforePrimaryCondition.executedDatetime !=
                afterPrimaryCondition.executedDatetime ||
                beforeSecondaryCondition.executedDatetime !=
                afterSecondaryCondition.executedDatetime))))
      ) {
        console.log(
          "chartData_changed",
          this.sensorData.primary.condition.sensorNumber,
          beforePrimaryCondition.executedDatetime,
          afterPrimaryCondition.executedDatetime,
          beforeSecondaryCondition.executedDatetime,
          afterSecondaryCondition.executedDatetime,
          this.sensorData.primary,
          this.sensorData.secondary
        );
        //条件の退避
        this.currentPrimaryCondition = this.sensorDataPrimary.condition;
        this.currentSecondaryCondition = this.sensorDataSecondary.condition;
        //値のセット画面再描画
        console.log(dataType);
        this.setChartValue("co2c",this.sensorData.getSensorChartDataList(this.user,"co2c"));
        this.setChartValue("temp",this.sensorData.getSensorChartDataList(this.user,"temp"));
        this.setChartValue("humi",this.sensorData.getSensorChartDataList(this.user,"humi"));
        this.chartValue = this.sensorData.getSensorChartDataList(this.user,dataType);
        //loadingの停止
        this.stopProcessing();
      } else {
        console.log(
          "chartData_nochanged",
          this.sensorData.primary.condition.sensorNumber,
          beforePrimaryCondition.executedDatetime,
          afterPrimaryCondition.executedDatetime,
          beforePrimaryCondition.sensorNumber,
          afterPrimaryCondition.sensorNumber,
          beforeSecondaryCondition.executedDatetime,
          afterSecondaryCondition.executedDatetime,
          this.sensorData.primary,
          this.sensorData.secondary
        );
      }

      //return this.sensorData.getSensorChartDataList(this.user);
      //return this.chartValue;
      return this.getChartValue(dataType);
    },    

    /** グラフ設定 */
    chartOption_visible(){
      if (this.propDataType == "temp"){
        return this.chartOption("temp");
      } else if (this.propDataType == "humi"){
        return this.chartOption("humi");
      } else {
        return this.chartOption("co2c");
      }
    },

    chartOption(dataType) {
      /** 各種設定値の詳細はリンク先にて。=> https://developers.google.com/chart/interactive/docs/gallery/linechart?hl=en#configuration-options */
      
      // const ticks = 
      //   dataType == "temp" ? /* 温度 */
      //     [ -10, -5, 0, 5,10, 15, 20, 25, 30, 35, 40, ] :
      //     dataType == "humi" ? /* 湿度 */
      //       [ 0, 10,20,30, 40, 50, 60, 70, 80,90,100, ] :
      //       /* CO2 */
      //       [
      //         this.hierarchy.lowerGreen,
      //         this.hierarchy.lowerYellow,
      //         this.hierarchy.lowerRed,
      //         this.maxPpm(),
      //       ]
      //       ;
      const format = 
        dataType == "temp" ? /* 温度 */
          "#℃" :
          dataType == "humi" ? /* 湿度 */
            "#％" :
            /* CO2 */
            "#ppm"
            ;         
      const max = 
        dataType == "temp" ? /* 温度 */
          40  :
          dataType == "humi" ? /* 湿度 */
            100  :
            /* CO2 */
            this.maxPpm()
            ;
      // const title = 
      //   dataType == "temp" ? /* 温度 */
      //     "温度"  :
      //     dataType == "humi" ? /* 湿度 */
      //       "湿度"  :
      //       /* CO2 */
      //       "CO2"
      //       ;  

      //分割数
      const count = 
        dataType == "temp" ? /* 温度 40℃を4分割*/
          4 :
          dataType == "humi" ? /* 湿度 100%を5分割*/
            5 :
            /* CO2 1500ppmを3分割*/
            3
            ;
      //
      const maxZoomOut =
        dataType == "temp" ? /* 温度 40℃を4分割*/
          2.0 :
          /* 湿度 CO2 マイナスを表示させないように1倍まで*/
            1.0;

      return {
        series: {
          0: { lineDashStyle: [0, 0] },
          1: { lineDashStyle: [2, 4] },
        },
        height: this.chartHeight,
        width: this.chartWidth,
        lineWidth: 3,
        backgroundColor: "transparent",
        baselineColor: this.getGridBackgroundColor(),
        legend: { position: "none" },
        explorer: {
            //axis: 'horizontal',
            keepInBounds: true, /* 初期表示領域より上下に移動できない */
            maxZoomIn: 0.05,
            maxZoomOut: maxZoomOut,
            zoomDelta: 1.2
        },
        tooltip: {
          trigger: "selection",
          textStyle: {
            fontSize: 18,
          },
        },
        fontName: "Noto Sans Japanese",
        // curveType: "function",
        chartArea: {
          width: "100%", height: "100%",
          top: 20, right: 20, bottom: 60, left: 80
        },
        vAxis: {
          textPosition: "out",
          baselineColor: this.getGridBackgroundColor(),
          format: format,
          textStyle: { color: "white" },
          //ticks: ticks,
          gridlines: {
            color: this.getGridBackgroundColor(),
            count: count
          },
          minorGridlines: {
            color: this.getGridBackgroundColor(),
          },
          viewWindowMode: "maximized",/* maximized | pretty // prettyだと最大最小を超えた際に縮尺が一気に小さくなる*/
          viewWindow: {
            max: max,
          },
          baseline: 0.0,
        },
        // title: title,
        titlePosition: "out",
        titleTextStyle: {
          color: 'white',
          fontSize: 15,

        },
        hAxis: {
          textPosition: "out",
          textStyle: { color: "white" },
          slantedText: true,
          showTextEvery: 1,
          gridlines: {
            color: "transparent",
          },
        },
      };
    },

    /** Loadingアイコンの停止 */
    stopProcessing() {
      this.$emit("stopProcessing");
    },
    /** グラフ縦軸ppmの最大値の算出 (ppmの最大値or最低ppmの大きい方を採用) */
    maxPpm() {
      //ppmの最大値
      const maxValue = Math.max(
        ...[].concat(
          this.sensorDataPrimary.detailList.map((x) => x.co2c),
          this.sensorDataSecondary.detailList.map((x) => x.co2c)
        )
      );
      //ppmの最大値or最低ppmの大きい方を採用
      if (maxValue < this.hierarchy.lowerRed) {
        return this.hierarchy.lowerRed;
      }
      //100ppm単位で丸め
      return Math.ceil(maxValue / 100) * 100;
    },

    onChartReady (chart, google) {
      this.google = google;
      this.chart = chart;
      this.chartThis = this;
    },


  },
};

export default vm;
</script>

<style>
.sensor-chart {
  border-radius: var(--card-border-radius);
  background-color: var(--sub-background-color);
  height: var(--chart-height);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2vw;
  margin-right: 2vw;

  float: right;
}

.sensor-chart .google-visualization-tooltip path {
  fill: var(--main-background-color);
}

.sensor-chart .google-visualization-tooltip text {
  fill: white;
}
</style>
