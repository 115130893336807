<template>
    <div class="sub-header">

        <div class="sub-header-title">
            <!-- <v-icon v-if="isMdi()" class="img sub-header-title_img" color="white">{{ img }}</v-icon>
            <img v-if="!isMdi()" class="img sub-header-title_img" :src="src()" /> -->
            <span class="sub-header-title_img">
                <img v-if="isCo2Data()" class="img usage-guide_img" src="../assets/icon_co2c.png" />
                <img v-if="isTempData()" class="img usage-guide_img" src="../assets/icon_thermometer.png" />
                <img v-if="isHumiData()" class="img usage-guide_img" src="../assets/icon_humidity.png" />
            </span>
            <span>{{ subtitle }}</span>
        </div>
        <div class="usage-guide">
            
            <img v-if="isCo2Data()" class="img usage-guide_img" src="../assets/icon_guide_co2_c.png" />
            <img v-if="isTempData()" class="img usage-guide_img" src="../assets/icon_guide_temp_c.png" />
            <img v-if="isHumiData()" class="img usage-guide_img" src="../assets/icon_guide_humi_c.png" />
            
        </div>        
    </div>
</template>

<script>
import mixinApp from "../store/mixin.app";
import mixinDetail from "../store/mixin.detail";
import constant from "../store/constant";

const vm = {
    mixins: [mixinApp, mixinDetail],
    props: {
        propImage: null,
        propSubtitle: null,
        propDataType: null,
        propRotation: null
    },
    computed: {
        bindStyle() {
            return {
                "--text-color-green": constant.green(),
                "--text-color-yellow": constant.yellow(),
                "--text-color-red": constant.red(),
            };
        },
        subtitle() {

            return this.propSubtitle;
        },
        img() {
            return this.propImage;
        },
    },
    methods: {
        isMdi() {
            return this.img.startsWith("mdi-");
        },
        src() {
            return constant.src(this.img);
        },
        /** グラフ値 */
        isCo2Data(){
            return this.propRotation.no == "0" // "co2c";
        },
        isTempData(){
            return this.propRotation.no == "1" // "temp";
        },
        isHumiData(){
            return this.propRotation.no == "2" // "humi";
        },        
    },
};

export default vm;
</script>

<style scoped>
/* .sub-header {
    height: 5vh;
    display: flex;
    align-items: center;
} */
/* 
.sub-header .img {
    height: 4vh;
    margin: 1vh;
} */
/* 
.usage-guide {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 0;
} */
/* .usage-guide span{
    height: 40px;
    line-height: 40px;
} */
/* .usage-guide .img {
    margin: 0;
    margin-left: 1vw;
} */
/* 
.usage-guide span {
    text-align: left;
    white-space: nowrap;
} */

.text-color-green {
    color: var(--text-color-green);
}

.text-color-yellow {
    color: var(--text-color-yellow);
}

.text-color-red {
    color: var(--text-color-red);
}
</style>
